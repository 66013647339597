
import {
    EditOutlined,
    PlayCircleOutlined
} from '@ant-design/icons-vue';
import TinymceEditor from '@/components/TinymceEditor';

export default {
    name: "ConfigContent",
    components: {
        EditOutlined,
        PlayCircleOutlined,
        TinymceEditor
    },
    data() {
        return {
            // 加载状态
            loading: false,
            // 内容列表
            contents: [],
            // 预览窗口显示状态
            view_visible: false,
            // 编辑窗口显示状态
            edit_visible: false,
            // 当前配置项KEY
            current_content_key: '',
            // 当前配置项内容
            current_content: '',
            // 提交状态
            submit_loading: false
        };
    },
    mounted() {
        this.load_data();
    },
    methods: {
        /**
         * 加载数据
         */
        load_data() {
            this.$http.get(`/admin/system_config/content_list`).then(res => {
                if (res.data.code === 0) {
                    this.contents = res.data.data;
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                this.$message.error(e.message);
            });
        },
        show(item, type) {
            this.$http.get(`/admin/system_config/get/${item.key}`).then(res => {
                if (res.data.code === 0) {
                    this.current_content_key = item.key;
                    this.current_content = res.data.data;
                    this[type] = true;
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                this.$message.error(e.message);
            });
        },
        handleOk() {
            var form = {};

            form[this.current_content_key] = this.current_content;

            this.submit_loading = true;
            this.$http.post(`/admin/system_config/update/content`, form).then(res => {
                this.submit_loading = false;

                if (res.data.code === 0) {
                    this.$message.success(res.data.msg);
                    this.edit_visible = false;
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                this.submit_loading = false;
                this.$message.error(e.message);
            });
        }
    }
}
